import axios from 'axios'
const user = JSON.parse(localStorage.getItem('user'))|| null
const account = JSON.parse(localStorage.getItem('account'))|| null

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

if (user && account) {
  http.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
  http.defaults.headers.common['Account'] = `${account.id}`
}

export default http